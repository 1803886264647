// Remove this loader later
import Loader from 'react-loader-spinner'
import React from 'react';

export default class LoaderComponent extends React.Component {
   //other logic
   render() {
      return (
            <Loader
               type="Oval"
               // type="Circles"
               color="#5798ff"
               // type="Oval"
               // color="#6d15c7"
               className="set-loader"
               timeout={1000} 
            />
      );
   }
}

import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify';

export class Spinner extends Component {
  render() {
    return (
      <div>
        <div className="spinner-wrapper">
          <div className="donut"></div>
          <ToastContainer />
        </div>
      </div>
    )
  }
}

export default Spinner

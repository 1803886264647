import React, { Component , Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import {Route, Switch, Redirect} from 'react-router-dom';
import axios from 'axios';
import routes from './routes';
import Loader from "./Loader/loader";
import Spinner from '../app/shared/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
// import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
let axiosInterceptor = null;
const AuthRouter = ({ component: Component, ...rest }) => {
  if (!!axiosInterceptor || axiosInterceptor === 0) {
      axios.interceptors.response.eject(axiosInterceptor);
  }
  axiosInterceptor = axios.interceptors.response.use(function (response) {
    console.log("inside axios interceptossssssssssssssss")
      return response;
  }, function (error) {
          console.log("ERROR", error.response)
          if (error.response.status === 401) {
              localStorage.removeItem('romenticthings_token');
              window.location.reload();
          }
          else{
            console.log("error",error.response);
            return error.response
          }
          
      }
  );
   return (
      <div>
      {console.log("token checkinggggg.................................................................",localStorage.getItem('romenticthings_token'))}
        <Route {...rest} render={props => (
          (localStorage.getItem('romenticthings_token') !== null) ? <Component {...props} /> :
            (
              <Redirect to={{
                pathname: "/login"
              }} />
            )
        )} />
      </div>
  )
}

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...<ToastContainer /></div>;

class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render () {

    routes.map((route, index) => {
      return (route.component) ? (
          <Route
              key={index}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => (
                  <route.component {...props} />
              )} />
      ) : (null);
  });
    // authrouter and authentication check  
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    return (
      <div className="container-scroller">
      {console.log("first render of app.js")}
        { sidebarComponent }
        <div className="container-fluid page-body-wrapper">
          { navbarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
            {console.log("before switch")}
            {/* <Suspense fallback={loading()}> */}
            <Suspense fallback={<Spinner/>}>
              <Switch fallback={<Loader/>}>
                {routes.map((route, idx) => {
                  {/* {console.log("routeeeeeeeeeeeeee",route)} */}
                  return route.component ? (
                     <AuthRouter path={route.path} component={route.component} key={idx} name="{route.name}" exact={route.exact} />
                       ) : (null);
                       })}
                    {/* <Redirect from="/" to="/dashboard" /> */}
                  <AppRoutes/>
                </Switch>
                </Suspense>
            </div>
            {/* { footerComponent } */}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  isPathActive(path) {
      return this.props.location.pathname.startsWith(path);
  }
  onRouteChanged = async () =>{
    console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    // const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    const fullPageLayoutRoutes = ['/login','/aboutus','/termsandconditions','/privacypolicy','/forgot-password'] 
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i] ||  this.isPathActive('/reset-password') ) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withTranslation()(withRouter(App));
// 7990945345

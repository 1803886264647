import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));
const Login = lazy(() => import('./user-pages/Login'));
const AboutUs = lazy(() => import('./StaticContentPages/AboutUs'));
const PrivacyPolicy = lazy(() => import('./StaticContentPages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./StaticContentPages/TermsAndConditions'));
const ForgotPassword = lazy(() => import('./ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'));
// all routes in this
class AppRoutes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner />}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route path="/error-pages/error-404" component={Error404} />
                    <Route path="/error-pages/error-500" component={Error500} />
                    <Route path="/aboutus" component={AboutUs} />
                    <Route path="/privacypolicy" component={PrivacyPolicy} />
                    <Route path="/termsandconditions" component={TermsAndConditions} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/reset-password/:id" component={ResetPassword} />
                    <Redirect to="/login" />
                </Switch>
            </Suspense>
        );
    }
}

export default AppRoutes;

import React from 'react';


const DashboardDefault = React.lazy(() => import('./dashboard/Dashboard'));
// const ChangePassword = React.lazy(() => import('./Demo/ChangePassword/ChangePassword'));
// const Profile = React.lazy(() => import('./Demo/Profile/Profile'));
const Content = React.lazy(() => import('./Content/ContentManagement'));
// const About = React.lazy(() => import('./Demo/Content/aboutUs/aboutUs'));
// const Privacy = React.lazy(() => import('./Demo/Content/privacyPolicy/privacyPolicy'));
// const Terms = React.lazy(() => import('./Demo/Content/termsAndCondition/termsAndCondition'));
const EmailTemplate = React.lazy(()=>import('./EmailTemplateManagement/EmailTemplateManagement'));
const RomanticThingsManagement = React.lazy(()=>import('./RomenticThingsManagement/RomanticThingsManagement'));
const SurveyManagement = React.lazy(()=>import('./SurveyManagement/SurveyManagement'))
const UserSuggesionsManagement = React.lazy(()=>import('./UserSuggesionManagement/UserSuggesionsManagement'))
const FeedbackManagement = React.lazy(()=>import('./FeedbackManagement/FeedbackManagement'))
const AddRomanticThings = React.lazy(()=>import('./RomenticThingsManagement/AddRomanticThings'))
const Profilepge = React.lazy(()=>import('./EditProfile/EditProfile'))
const UpdateRomanticThings = React.lazy(()=>import('./RomenticThingsManagement/UpdateRomanticThing'));
const AddUserRomanticSuggestions = React.lazy(()=>import('./RomenticThingsManagement/AddUserRomanticSuggestions'));
//token vada pages aama
const routes = [
    { path: '/dashboard', exact: true, name: 'Dashboard', component: DashboardDefault },
    // { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    // { path: '/profile', exact: true, name: 'Profile', component: Profile },
    { path: '/content', exact: true, name: 'Content', component: Content },  
    // { path: '/content/about-us', exact: true, name: 'About', component: About },  
    // { path: '/content/privacy-policy', exact: true, name: 'Privacy', component: Privacy },  
    // { path: '/content/termsandcondition', exact: true, name: 'Terms', component: Terms },
    { path: '/email-template', exact: true, name: 'email template', component: EmailTemplate },
    { path: '/romantic-things-management', exact: true, name: 'Romentic Things', component: RomanticThingsManagement },
    { path: '/user-suggesions', exact: true, name: 'User Suggesions', component: UserSuggesionsManagement },
    { path: '/feedback-management', exact: true, name: 'User Suggesions', component: FeedbackManagement },
    { path: '/surveey-management', exact: true, name: 'User Suggesions', component: SurveyManagement },
    { path: '/add-romantic-things', exact: true, name: 'Add Romantic Thing', component:  AddRomanticThings},
    { path: '/update-romantic-things/:id', exact: true, name: 'Add Romantic Thing', component:  UpdateRomanticThings},
    { path: '/edit-profile', exact: true, name: 'Profile Page', component:  Profilepge},
    { path: '/approve-user-suggestions/:id', exact: true, name: 'Approve User Suggestions', component:  AddUserRomanticSuggestions},
    // { path: '/', exact: true, name: 'User Suggesions', component: EmailTemplate },
    // { path: '/email-template', exact: true, name: 'User Suggesions', component: EmailTemplate },
    // { path: '/add-card', exact: true, name: 'Add Card', component: AddCard },
    
];

export default routes;